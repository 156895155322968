.mitrol-expanded-widget-vertical {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 1em;
    opacity: 0;
    transform: scale(0.2);
    box-sizing: content-box;
    position: relative;
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  .mitrol-expanded-widget-vertical.expanded {
    opacity: 1;
    transform: scale(1);
  }
  
  .mitrol-mitrolbutton-container-vertical {
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 50% !important;
    width: 43px;
    height: 43px;
    transition: transform 0.4s ease-in-out;
    background-color: transparent;
  }
  
  .mitrol-mitrolbutton-inside-vertical {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .mitrol-titulo-vertical {
    font-family: "Lato", sans-serif !important;
    font-style: normal !important;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    bottom: -3.4em !important;
    z-index: 90 !important;
    transition-property: all;
    transition-duration: 0.35s;
    text-align: center;
    width: 100px;
    margin-top: 0.7em;
    cursor: pointer;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .mitrol-mitrolbutton-container-vertical:hover {
    transform: scale(1.1);
  }
  
  .mitrol-mitrolbutton-container-vertical.iframe-hover {
    transform: none;
    box-shadow: none;
  }
  

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .spinner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8); /* Fondo semi-transparente */
    z-index: 101; /* Asegura que el spinner esté sobre el iframe */
  }
  
  .spinner-overlay.fade-in {
    animation: fadeIn 0.5s ease-out forwards;
  }
  
  .mitrolbutton-close-iframe {
    background-color: rgb(194, 0, 18);
    border-radius: 50%;
    color: white;
    position: absolute;
    z-index: 102; /* Asegura que el botón de cerrar esté sobre el overlay */
    left: 1em;
    top: 1em;
    transition-property: all;
    transition-duration: 0.35s;
  }
  
  .mitrolbutton-close-iframe:hover {
    background-color: rgb(200, 0, 28);
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  @media only screen and (max-width: 500px) {
   
  
    .iframeMitrol {
      height: 31em;
    }
  }
  
  @media (min-resolution: 120dpi) {

  }
  