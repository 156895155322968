.mitrol-expanded-widget-vertical {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  gap: 1em;
  opacity: 0;
  transform: scale(0.2);
  box-sizing: content-box;
  position: relative;
  left: -105%;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.mitrol-expanded-widget-vertical.expanded {
  opacity: 1;
  transform: scale(1);
}

.mitrol-mitrolbutton-container-vertical {
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  width: 43px;
  height: 43px;
  transition: transform 0.4s ease-in-out;
  background-color: transparent;
}

.mitrol-mitrolbutton-inside-vertical {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.mitrol-titulo-vertical {
  font-family: "Lato", sans-serif !important;
  font-style: normal !important;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  bottom: -3.4em !important;
  z-index: 90 !important;
  transition-property: all;
  transition-duration: 0.35s;
  text-align: center;
  width: 100px;
  margin-top: 0.7em;
  cursor: pointer;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mitrol-mitrolbutton-container-vertical:hover {
  transform: scale(1.1);
}

.mitrol-mitrolbutton-container-vertical.iframe-hover {
  transform: none;
  box-shadow: none;
}
