.mitrol-expanded-widget-circular {
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0;
  height: 0;
  right: -34em;
  bottom: -29em;
  position: fixed;
  z-index: 1;
  opacity: 0;
  transform: scale(0.2);
  box-sizing: content-box;
  transition: opacity 0.7s ease, transform 0.7s ease;
}

.mitrol-expanded-widget-circular.expanded {
  width: 49.5em;
  height: 42em;
  opacity: 1;
  transform: scale(1);
}


.mitrol-titulo-circular {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  z-index: 90;
  text-align: center;
  width: 50px;
  cursor: pointer;
  width: 100px;
}

.mitrol-mitrolbutton-container-circular {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 90 !important;
  transition: transform 0.4s ease-in-out;
}

.mitrol-mitrolbutton-container-circular:hover {
  transform: scale(1.02);
  transform-origin: center;
}
.mitrol-mitrolbutton-container-circular.iframe-hover {
  transform: none;
  box-shadow: none;
}

.mitrol-expanded-widget-circular .mitrol-mitrolbutton-container-circular {
  position: absolute;
  transform-origin: center;
}

.mitrol-mitrolbutton-inside-circular {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  transition: transform 0.4s ease-in-out;
}

.mitrol-mitrolbutton-inside-circular:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
}

.mitrol-expanded-widget-circular button {
  border: none;
  color: white;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.single-mitrolbutton-style.expanded {
  width: 46.5em !important;
  height: 40.5em !important;
  opacity: 1;
  transform: scale(1);
}

.double-mitrolbutton-style.expanded {
  width: 45.5em !important;
  height: 42em;
  opacity: 1;
  transform: scale(1);
}

.mitrolbutton-single-style {
  top: 14.8%;
  left: 10.3%;
}

.mitrolbutton-double-first-style {
  top: 21%;
  left: 5.5%;
}

.mitrolbutton-double-second-style {
  top: 11%;
  left: 14.5%;
}

.mitrolbutton-triple-first-style {
  top: 22.7%;
  left: 5%;
}

.mitrolbutton-triple-second-style {
  top: 14%;
  left: 12.5%;
}

.mitrolbutton-triple-third-style {
  top: 7.5%;
  left: 21.5%;
}

.text-box-inside-widget {
  width: 6em;
  height: 2em;
  position: relative;
  left: 12%;
  z-index: -200;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 0px;
}

.title-text-box-inside-widget {
  font-family: Verdana;
  font-size: 11px;
  margin-right: 10px;
  max-width: 75px;
  text-align: center;
}

.iconmitrol-mitrolbutton-container{
  background-color:red;
  border-radius:50%;
  width: 50px;
  height: 50px;
}


.mitrol-expanded-widget-vertical {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  gap: 1em;
  opacity: 0;
  transform: scale(0.2);
  box-sizing: content-box;
  position: relative;
  left: -105%;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.mitrol-expanded-widget-vertical.expanded {
  opacity: 1;
  transform: scale(1);
}

.mitrol-mitrolbutton-container-vertical {
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  width: 43px;
  height: 43px;
  transition: transform 0.4s ease-in-out;
  background-color: transparent;
}

.mitrol-mitrolbutton-inside-vertical {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.mitrol-expanded-widget-vertical button {
  border: none;
  width: 30px;
  height: 30px;
}

.mitrol-titulo-vertical {
  font-family: "Lato", sans-serif !important;
  font-style: normal !important;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  bottom: -3.4em !important;
  z-index: 90 !important;
  transition-property: all;
  transition-duration: 0.35s;
  text-align: center;
  width: 100px;
  margin-top: 0.7em;
  cursor: pointer;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mitrol-mitrolbutton-container-vertical:hover {
  transform: scale(1.1);
}

.mitrol-mitrolbutton-container-vertical.iframe-hover {
  transform: none;
  box-shadow: none;
}


.iframeMitrol {
  border-radius: 5%;
  width: 350px;
  height: 32em;
  border: none;
  z-index: 98;
}

.iframeExpanded {
  animation: myAnim 2s ease 0s 1 normal forwards;
}

.iframeNotExpanded {
  animation: myAnim 2s ease 0s 1 normal forwards;
}

.iframeMitrol-circular {
  border-radius: 5%;
  width: 350px;
  height: 32em;
  border: none;
  z-index: 98;
}

.mitrolbutton-close-iframe {
  background-color: rgb(194, 0, 18);
  border-radius: 50%;
  color: white;
  position: absolute;
  z-index: 99;
  left: 1em;
  top: 1em;
  transition-property: all;
  transition-duration: 0.35s;
}

.mitrolbutton-close-iframe:hover {
  background-color: rgb(200, 0, 28);
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.spinner-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
}

.one-button {
  top: -29em;
}

.two-buttons {
  top: -29em;
}

.three-buttons {
  top: -29em;
}

.one-mitrolbutton-circular {
  position: absolute;
  top: -37em;
  left: -14em;
}

.two-buttons-circular {
  position: absolute;
  top: -37em;
  left: -17em;
}

.three-buttons-circular {
  position: absolute;
  top: -37em;
  left: -18em;
}

@media only screen and (max-width: 500px) {

 
  .one-button {
    top: -32.3em;
  }

  .two-buttons {
    top: -29.6em;
  }

  .one-mitrolbutton-circular {
    top: -37em;
    left: -13.5em;
  }

  .two-buttons-circular {
    top: -40em;
    left: -12.5em;
  }

  .three-buttons-circular {
    top: -40em;
    left: -8.5em;
  }

  .iframeMitrol-circular {
    height: 31em;
  }
}

@media (min-resolution: 120dpi) {
  
}

@keyframes myAnim {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: translateX(250px);
  }

  25% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: translateX(0);
  }

  50% {
    animation-timing-function: ease-in;
    transform: translateX(67px);
  }

  75% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  100% {
    animation-timing-function: ease-in;
    transform: translateX(0);
  }
}
