/* CircularWidget.css */

.mitrol-expanded-widget-circular {
  border-radius: 50%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0;
  height: 0;
  right: -34em;
  bottom: -29em;
  position: fixed;
  z-index: 1;
  opacity: 0;
  transform: scale(0.2);
  box-sizing: content-box;
  transition: opacity 0.3s ease, transform 0.3s ease, width 0.3s ease, height 0.3s ease;
}

.mitrol-expanded-widget-circular.expanded {
  width: 49.5em;
  height: 42em;
  opacity: 1;
  transform: scale(1);
}

.mitrol-titulo-circular {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  z-index: 90;
  text-align: center;
  width: 100px;
  cursor: pointer;
}

.mitrol-mitrolbutton-container-circular {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 90 !important;
  transition: transform 0.4s ease-in-out;
}

.mitrol-mitrolbutton-container-circular:hover {
  transform: scale(1.02);
  transform-origin: center;
}

.mitrol-mitrolbutton-container-circular.iframe-hover {
  transform: none;
  box-shadow: none;
}

.mitrol-expanded-widget-circular .mitrol-mitrolbutton-container-circular {
  position: absolute;
  transform-origin: center;
}

.mitrol-mitrolbutton-inside-circular {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  transition: transform 0.4s ease-in-out;
}

.mitrol-mitrolbutton-inside-circular:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
}

.mitrol-expanded-widget-circular button {
  border: none;
  color: white;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.single-mitrolbutton-style.expanded {
  width: 47.5em !important;
  height: 41.5em !important;
  opacity: 1;
  transform: scale(1);
}

.double-mitrolbutton-style.expanded {
  width: 45.5em !important;
  height: 42em;
  opacity: 1;
  transform: scale(1);
}

.mitrolbutton-single-style {
  top: 14.8%;
  left: 10.3%;
}

.mitrolbutton-double-first-style {
  top: 21%;
  left: 5.5%;
}

.mitrolbutton-double-second-style {
  top: 11%;
  left: 14.5%;
}

.mitrolbutton-triple-first-style {
  top: 22.7%;
  left: 5%;
}

.mitrolbutton-triple-second-style {
  top: 14%;
  left: 12.5%;
}

.mitrolbutton-triple-third-style {
  top: 7.5%;
  left: 21.5%;
}

.text-box-inside-widget {
  width: 6em;
  height: 2em;
  position: relative;
  left: 12%;
  z-index: -200;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 0px;
}

.title-text-box-inside-widget {
  font-family: Verdana;
  font-size: 11px;
  margin-right: 10px;
  max-width: 75px;
  text-align: center;
}

.one-mitrolbutton-circular {
  position: absolute;
  top: 5.5em;
  left: 6em;
}

.two-buttons-circular {
  position: absolute;
  top: -37em;
  left: -17em;
}

.mitrolbutton-icon {
  width: 45px;
  height: 45px;
}

.three-buttons-circular {
  position: absolute;
  top: -37em;
  left: -18em;
}

@media only screen and (max-width: 500px) {
  .iframe-container {
    top: -25.4em;
    left: -14em !important;
  }

  .one-mitrolbutton-circular {
    top: 6em;
    left: 5.5em;
  }

  .two-buttons-circular {
    top: -40em;
    left: -12.5em;
  }

  .three-buttons-circular {
    top: -40em;
    left: -8.5em;
  }

  .iframeMitrol-circular {
    height: 31em;
  }
}
