.default-widget-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: sans-serif;
  background-color: aquamarine;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
