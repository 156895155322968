.widget-button {
  z-index: 2;
  display: flex;
  flex-direction: column-reverse;
  gap: 3em;
  align-items: center;
  transition: all 0.5s ease;
}

.widget-mitrolbutton-vertical-v2 {
  z-index: 2;
  transition: all 0.5s ease;
  position: relative;
  left: -164%;
}



.widget-rectangle {
  cursor: pointer;
  width: 17em;
  height: 2.87em;
  margin-bottom: -1.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-bottom: 3px;
  opacity: 1;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transform: translateY(0);
  z-index:99999;
  position:relative
}



.widget-rectangle.hidden {
  opacity: 0;
  transform: translateY(20px);
}




.mitrolbutton-close-iframe {
  background-color: rgb(194, 0, 18);
  border-radius: 50%;
  width:1.6em;
  height: 1.6em;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  z-index: 99;
  left: 1em;
  top: 1em;
  border: 0;
}

.mitrolbutton-close-iframe:hover{
  background-color: rgb(200,0,28);
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}


.text-rectangle {
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  margin-bottom: 1px;
  padding-left: 7px;
}

iframe{
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.rectangle-iframe-container {
  position: fixed;
  bottom: 0px;
  right: 30px;
  width: 19em;
  height: 25em;
  background-color: transparent;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  visibility: visible;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease, visibility 0s 0.5s;
}


.vertical-v2-text-box-container{
  cursor:pointer;
  font-family:verdana;
  text-align:center;
  display:flex;
  justify-content: flex-end;
  align-items:center;
  height: 5.2em;
  width: 21.5em;
  position:absolute;
  left:-165%;
  border-radius:50px;
  padding:3px 8px;
  transition: background-color 0.5s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 768px) and (orientation: portrait) {
  .vertical-v2-text-box-container{
    cursor:pointer;
    font-family:verdana;
    text-align:center;
    display:flex;
    justify-content: flex-end;
    align-items:center;
    height: 5.2em;
    width: 21em;
    position:absolute;
    left:-150%;
    border-radius:50px;
    padding:3px 8px;
    transition: background-color 0.5s ease;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);    
  }

  .widget-mitrolbutton-vertical-v2 {
    z-index: 2;
    transition: all 0.5s ease;
    position: relative;
    left: -150%;
  }
}

.vertical-v2-title-text-box{
  font-size: 17px;
  font-weight: 700;
  margin-right: 5px;
  margin-bottom: -5px;
}

.vertical-v2-subtitle-text-box{
  font-size: 12px;
  font-weight: 700;
  margin-right: 8px;
  letter-spacing: -0.9px;
}



.vertical-text-box-container{
  cursor:pointer;
  font-family:verdana;
  font-size: 12px;
  text-align:center;
  display:flex;
  align-items:center;
  height: 2.5em;
  width: 12em;
  position:absolute;
  left:-275%;
  border-radius:10px;
  margin-bottom: 0.3em;
  padding:3px 5px;
  transition: background-color 0.5s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8)
}

.vertical-text-box-content{
  margin-right:2em;
}

.expanded-widget-container {
  display: flex;
  justify-content: center;
}

.rectangle-iframe-container {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.rectangle-iframe-container.visible {
    visibility: visible;
    opacity: 1;
  transform: translateY(-20px); 
}
