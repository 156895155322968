.iframe-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: rgb(207, 207, 207);
  border: none;
  border-radius: 5%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  z-index: 100;
  transition: opacity 0.5s ease, transform 0.5s ease;
  left: -15.5em;
  top: -34em;
}

.iframe-container.expanded-buttons {
  height: 42em;
}

.iframe-container.expanded-buttons.unique-button {
  top: -40em;
}

.iframe-container.show-spinner {
  opacity: 1;
  transform: scale(1);
  animation: fadeIn 0.5s ease-out forwards;
}

.iframe-container.fade-in {
  animation: fadeIn 0.5s ease-out forwards;
}

.iframe-container.two-buttons.expanded-buttons {
  top: -34em;
}

.iframe-container.two-buttons.not-expanded-buttons {
  top: -33em;
}

.iframe-container.three-buttons.expanded-buttons {
  top: -30.6em;
}

.iframe-container.three-buttons.not-expanded-buttons {
  top: -33em;
}

.iframe-container.four-buttons.expanded-buttons {
  top: -27em;
}

.iframe-container.four-buttons.not-expanded-buttons {
  top: -29em;
}

.iframe-container.five-buttons.expanded-buttons {
  top: -23em;
}

.iframe-container.five-buttons.not-expanded-buttons {
  top: -29em;
}

.iframe-container.unique-button {
  left: -16em;
  top: -28em;
}

.iframe-container-v2 {
  left: -10.5em;
  top: -27.2em;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  border-radius: 5%;
}

.iframe-container-circular {
  left: -17.5em;
  top: -35em;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  border-radius: 5%;
}

.iframe-container-horizontal {
  z-index: 1;
  border-radius: 20px;
  transform: translateY(180px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.iframe-container-horizontal.fade-in {
  transform: translateY(0px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.iframeMitrol.expanded-buttons {
  height: 100%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  z-index: 101;
}

.spinner-overlay.fade-in {
  animation: fadeIn 0.5s ease-out forwards;
}

.mitrolbutton-close-iframe-left,
.mitrolbutton-close-iframe-right,
.mitrolbutton-close-iframe-none {
  background-color: rgb(219, 0, 22);
  border-radius: 50%;
  color: white;
  position: absolute;
  z-index: 102;
  top: 1em;
  transition-property: all;
  transition-duration: 0.35s;
  border: none;
  width: 30px;
  height: 30px;
}

.mitrolbutton-close-iframe-left {
  left: 1em;
}

.mitrolbutton-close-iframe-left.two-buttons {
  left: 1em;
  top: 1em;
}


.mitrolbutton-close-iframe-left.three-buttons {
  left: 1em;
  top: 1em;
}

.mitrolbutton-close-iframe-none {
  z-index: 9999999999999999999 !important;
  background-color: transparent;
  width: 60px !important;
  height: 60px !important;
}

.mitrolbutton-close-iframe-none.three-buttons {
}




.mitrolbutton-close-iframe-right {
  right: 1em;
}

.mitrolbutton-close-iframe-right.one-button {
  right: 1em;
  top: 1em;
}

.mitrolbutton-close-iframe-right.two-buttons {
  right: 1em;
  top: 1em;
}


.mitrolbutton-close-iframe-right.three-buttons {
  right: 1em;
  top: 1em;
}

.mitrolbutton-close-iframe-none.four-buttons {
  right: -1em;
  top: 53.1em;
}


.mitrolbutton-close-iframe-none.five-buttons {
  right: -1em;
  top: 53em;
}


.mitrolbutton-close-iframe-left:hover,
.mitrolbutton-close-iframe-right:hover {
  background-color: rgb(134, 0, 18);
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}


.mitrolbutton-close-iframe-none {
  right: -3.9%;
  cursor:pointer;
}

.mitrolbutton-close-iframe-none.two-buttons.expanded-buttons {
  top: 104%;
}

.mitrolbutton-close-iframe-none.three-buttons.expanded-buttons,
.mitrolbutton-close-iframe-none.four-buttons.expanded-buttons,
.mitrolbutton-close-iframe-none.five-buttons.expanded-buttons {
  top: 105%;
}


@media only screen and (max-width: 500px) {
  .iframe-container {
    top: -29em;
    left: -14em;
  }

  .iframe-container.unique-button {
    left: -15em;
    top: -26em;
  }

  .iframe-container.expanded-buttons.unique-button {
    top: -33em;
    scale: 0.8;
  }

  .iframe-container.two-buttons {
    left: -13em !important;
  }

  .iframe-container.two-buttons.expanded-buttons {
    top: -30em;
  }

  .iframe-container.two-buttons.not-expanded-buttons {
    top: -28.9em;
  }

  .iframe-container.three-buttons {
    left: -13em !important;
  }

  .iframe-container.three-buttons.expanded-buttons {
    top: -26em;
  }

  .iframe-container.three-buttons.not-expanded-buttons {
    top: -28.9em;
  }

  .iframe-container.four-buttons {
    left: -13em !important;
  }

  .iframe-container.four-buttons.expanded-buttons {
    top: -23em;
  }

  .iframe-container.four-buttons.not-expanded-buttons {
    top: -24.5em;
  }

  .iframe-container.five-buttons {
    left: -13em !important;
  }

  .iframe-container.five-buttons.expanded-buttons {
    top: -19em;
  }

  .iframe-container.five-buttons.not-expanded-buttons {
    top: -21em;
  }

  .mitrolbutton-close-iframe-none {
    right: -0.8em;
    cursor:pointer;
    width: 75px !important;
    height: 75px !important;
  }

  .mitrolbutton-close-iframe-none.two-buttons.expanded-buttons,
  .mitrolbutton-close-iframe-none.three-buttons.expanded-buttons,
  .mitrolbutton-close-iframe-none.four-buttons.expanded-buttons,
  .mitrolbutton-close-iframe-none.five-buttons.expanded-buttons{
    right: -3.2%
  }


  .mitrolbutton-close-iframe-none.two-buttons.expanded-buttons {
    top: 105.5%;
  }


  .mitrolbutton-close-iframe-none.three-buttons.expanded-buttons {
    top: 105%;
  }

  .mitrolbutton-close-iframe-none.four-buttons.expanded-buttons {
    top: 107.3%;
  }

  .mitrolbutton-close-iframe-none.five-buttons.expanded-buttons {
    top: 106.7%;
    right: -3.2%
  }


  .iframe-container-v2.unique-button {
    left: -9em !important;
  }

  .iframeMitrol {
    height: 31em;
  }

  .iframe-one-mitrolbutton-circular {
    top: -37em;
    left: -12.9em;
  }

  .iframe-mitrolbutton-double-first-style {
    top: -37.9em;
    left: -12em;
  }

  .iframe-mitrolbutton-double-second-style {
    top: -33.5em;
    left: -16.2em;
  }

  .iframe-mitrolbutton-triple-first-style {
    top: -39.8em;
    left: -8em;
  }

  .iframe-mitrolbutton-triple-second-style {
    top: -36.05em;
    left: -11.8em;
  }

  .iframe-mitrolbutton-triple-third-style {
    top: -33.25em;
    left: -16.37em;
  }
}

@media (min-resolution: 120dpi) {
  .iframe-container {
    scale: 0.8;
    top: -22.6em;
  }

  .iframe-container.unique-button {
    scale: 0.925;
  }
}
